var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-stepper',{staticClass:"main-grey-light pb-6",attrs:{"color":"green"},model:{value:(_vm.curr),callback:function ($$v) {_vm.curr=$$v},expression:"curr"}},[_c('v-stepper-header',{staticClass:"pt-10 pb-0 d-flex align-center justify-center border-none shadow-none"},[_c('h6',{staticClass:"mr-2 blue--text stepper-head-text"},[_vm._v("新規会員登録")]),_vm._l((_vm.steps),function(step,n){return _c('v-stepper-step',{key:n,staticClass:"px-0 py-0 mr-1 stepper-step",attrs:{"complete":_vm.stepComplete(n + 1),"step":("" + (n + 1)),"rules":[function (value) { return !!step.valid; }],"color":_vm.stepStatus(n + 1)}},[(n + 1 < _vm.lastStep)?_c('span',[_vm._v(">")]):_vm._e()])})],2),_vm._l((_vm.steps),function(step,n){return _c('v-stepper-content',{key:n,attrs:{"step":n + 1}},[_c('validation-observer',{ref:'observer',refInFor:true},[_c('v-form',{ref:'stepForm',refInFor:true,attrs:{"lazy-validation":""},model:{value:(step.valid),callback:function ($$v) {_vm.$set(step, "valid", $$v)},expression:"step.valid"}},_vm._l((step.fields),function(field){return _c('v-card-text',{key:field.name,staticClass:"pb-0"},[(field.type == 'password')?_c('v-row',[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v(_vm._s(field.label))]),_c('v-text-field',{attrs:{"placeholder":field.placeholder,"dense":"","solo":"","error-messages":errors,"min":"8"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)],1):_vm._e(),(field.type == 'text')?_c('v-row',[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v(_vm._s(field.label))]),_c('v-text-field',{attrs:{"placeholder":field.placeholder,"dense":"","solo":"","error-messages":errors},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1),(field.additional_field)?_c('v-col',{staticClass:"py-0 px-0 pl-3",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.additional_field.rules,"name":field.additional_field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"hide-visible"},[_c('v-label',[_vm._v("sr-only")])],1),_c('v-text-field',{attrs:{"dense":"","solo":"","placeholder":field.additional_field.placeholder,"error-messages":errors},model:{value:(field.additional_field.value),callback:function ($$v) {_vm.$set(field.additional_field, "value", $$v)},expression:"field.additional_field.value"}})]}}],null,true)})],1):_vm._e()],1):_vm._e(),(field.type == 'dropdown')?_c('v-row',[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v(_vm._s(field.label))]),_c('v-select',{attrs:{"label":field.placeholder,"dense":"","solo":"","items":field.listValues,"error-messages":errors},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1),(field.additional_field)?_c('v-col',{staticClass:"py-0 px-0 pl-3",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.additional_field.rules,"name":field.additional_field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"hide-visible"},[_c('v-label',[_vm._v("sr-only")])],1),_c('v-select',{attrs:{"dense":"","solo":"","items":field.additional_field.listValues,"label":field.additional_field.placeholder,"error-messages":errors},model:{value:(field.additional_field.value),callback:function ($$v) {_vm.$set(field.additional_field, "value", $$v)},expression:"field.additional_field.value"}})]}}],null,true)})],1):_vm._e()],1):_vm._e(),(field.type == 'image')?_c('v-row',[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v(_vm._s(field.label))]),_c('v-file-input',{staticClass:"prepend-icon-class",attrs:{"accept":"image/*","placeholder":"Choose File","dense":"","solo":"","prepend-icon":"mdi-plus","error-messages":errors},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)],1):_vm._e()],1)}),1)],1),(n + 1 < _vm.lastStep)?_c('v-btn',{staticClass:"stepper-btns mt-2",attrs:{"block":"","color":"primary","disabled":!step.valid},on:{"click":function($event){return _vm.validate(n)}}},[_vm._v("次へ")]):_c('v-btn',{staticClass:"stepper-btns mt-2",attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.validate(n)}}},[_vm._v("登録完了")]),(_vm.curr != 1)?_c('v-btn',{staticClass:"mt-3",attrs:{"block":"","color":"grey white--text"},on:{"click":function($event){_vm.curr = n}}},[_vm._v("戻る")]):_vm._e()],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }