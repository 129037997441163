<template>
  <v-container>
    <v-stepper v-model="curr" color="green" class="main-grey-light pb-6">
      <v-stepper-header
        class="pt-10 pb-0 d-flex align-center justify-center border-none shadow-none"
      >
        <h6 class="mr-2 blue--text stepper-head-text">新規会員登録</h6>
        <v-stepper-step
          v-for="(step, n) in steps"
          :key="n"
          :complete="stepComplete(n + 1)"
          :step="`${n + 1}`"
          :rules="[value => !!step.valid]"
          :color="stepStatus(n + 1)"
          class="px-0 py-0 mr-1 stepper-step"
        >
          <span v-if="n + 1 < lastStep">></span>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-content v-for="(step, n) in steps" :step="n + 1" :key="n">
        <validation-observer :ref="'observer'">
          <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation>
            <!-- Step 1 -->
            <v-card-text
              class="pb-0"
              v-for="field in step.fields"
              :key="field.name"
            >
              <v-row v-if="field.type == 'password'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="py-0 px-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label>{{ field.label }}</v-label>
                    <v-text-field
                      :placeholder="field.placeholder"
                      dense
                      solo
                      v-model="field.value"
                      :error-messages="errors"
                      min="8"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="field.type == 'text'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="py-0 px-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label>{{ field.label }}</v-label>
                    <v-text-field
                      :placeholder="field.placeholder"
                      dense
                      solo
                      v-model="field.value"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  v-if="field.additional_field"
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="py-0 px-0 pl-3"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.additional_field.rules"
                    :name="field.additional_field.name"
                  >
                    <div class="hide-visible">
                      <v-label>sr-only</v-label>
                    </div>
                    <v-text-field
                      dense
                      solo
                      v-model="field.additional_field.value"
                      :placeholder="field.additional_field.placeholder"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row v-if="field.type == 'dropdown'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="py-0 px-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label>{{ field.label }}</v-label>
                    <v-select
                      :label="field.placeholder"
                      dense
                      solo
                      :items="field.listValues"
                      v-model="field.value"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col
                  v-if="field.additional_field"
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="py-0 px-0 pl-3"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.additional_field.rules"
                    :name="field.additional_field.name"
                  >
                    <div class="hide-visible">
                      <v-label>sr-only</v-label>
                    </div>
                    <v-select
                      dense
                      solo
                      :items="field.additional_field.listValues"
                      v-model="field.additional_field.value"
                      :label="field.additional_field.placeholder"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row v-if="field.type == 'image'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="py-0 px-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label>{{ field.label }}</v-label>
                    <v-file-input
                      accept="image/*"
                      placeholder="Choose File"
                      class="prepend-icon-class"
                      dense
                      solo
                      prepend-icon="mdi-plus"
                      :error-messages="errors"
                      v-model="field.value"
                    >
                    </v-file-input>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </validation-observer>
        <v-btn
          block
          v-if="n + 1 < lastStep"
          color="primary"
          class="stepper-btns mt-2"
          @click="validate(n)"
          :disabled="!step.valid"
          >次へ</v-btn
        >
        <v-btn
          v-else
          class="stepper-btns mt-2"
          block
          color="primary"
          @click="validate(n)"
          >登録完了</v-btn
        >
        <v-btn
          block
          class="mt-3"
          color="grey white--text"
          v-if="curr != 1"
          @click="curr = n"
          >戻る</v-btn
        >
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  name: "RegisterPage",
  data() {
    return {
      errors: "",
      formStatus: false,
      curr: 1,
      lastStep: 1,
      steps: [
        {
          name: "Step 1",
          valid: true,
          fields: [
            {
              label: "登録者名",
              name: "last_name",
              type: "text",
              placeholder: "姓",
              value: "",
              rules: "required",
              additional_field: {
                label: "",
                name: "first_name",
                type: "text",
                placeholder: "名",
                value: "",
                rules: "required"
              }
            },
            {
              label: "Furigana",
              name: "last_name_kana",
              type: "text",
              placeholder: "姓",
              value: "",
              rules: "required",
              additional_field: {
                label: "Fist kana name",
                name: "first_name_kana",
                type: "text",
                placeholder: "名",
                value: "",
                rules: "required"
              }
            },
            {
              label: "メールアドレス(ID)",
              name: "email",
              type: "text",
              placeholder: "mail@crosspoint.com",
              value: "",
              rules: "required|email"
            },
            {
              label: "パスワード",
              name: "password",
              type: "password",
              placeholder: "8文字以上の英数字を設定してください",
              value: "",
              rules: "required|max:50"
            },
            {
              label: "パスワード(確認用)",
              name: "password_confirmation",
              type: "password",
              placeholder: "もう一度入力してください",
              value: "",
              rules: "required|max:50|password_confirmed:password"
            }
          ]
        },
        {
          name: "Complete",
          valid: true,
          fields: [
            {
              label: "会社名",
              name: "company_name",
              type: "text",
              placeholder: "クロスポイント株式会社",
              value: "",
              rules: "required"
            },
            {
              label: "役職",
              name: "position",
              type: "text",
              placeholder: "エリアマネージャー",
              value: "",
              rules: "required"
            },
            {
              label: "電話番号",
              name: "phone_number",
              type: "text",
              placeholder: "03-5413-1800",
              value: "",
              rules: "required"
            }
          ]
        }
      ],
      valid: false,
      stepForm: [],
      observer: []
    };
  },
  methods: {
    stepComplete(step) {
      return this.curr > step;
    },
    stepStatus(step) {
      return this.curr > parseInt(step) ? "green" : "blue";
    },
    validate(n) {
      this.steps[n].valid = false;
      this.$refs.observer[n].validate().then(success => {
        if (!success) {
          return;
        }
        this.steps[n].valid = true;
        // continue to next
        this.curr = n + 2;
        if (success && n + 1 > this.lastStep) {
          this.register();
        }
      });
    },
    register() {
      let data = new FormData();
      if (typeof this.file !== "string") data.append("photo", this.file);
      this.steps.forEach(step => {
        step.fields.forEach(field => {
          if (field) {
            if ({}.hasOwnProperty.call(field, "additional_field")) {
              data.append(
                field.additional_field.name,
                field.additional_field.value
              );
            }
            data.append(field.name, field.value);
          }
        });
      });
      let this_this = this;
      this.$store
        .dispatch("USER_REGISTER", data)
        .then(response => {
          if (response.status === 200) {
            this_this.$router.push("/login");
          }
        })
        .catch(error => {
          this_this.steps.forEach((value, key) => {
            this_this.$refs.observer[key].setErrors(error.data.error.errors);
          });
          this_this.curr = 1;
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
